import React from 'react'
import { Helmet } from 'react-helmet'
import PageContainer from '../../components/PageContainer/PageContainer'
import Title from '../../components/Title/Title'

import Layout from '../../Layouts/Layout'
import LatestBlogs from '../../modules/BlogRoll/BlogRoll'

const BlogIndexPage = () => (
  <Layout>
    <Helmet title="Blog" />
    <Title>Latest Posts</Title>
    <PageContainer>
      <LatestBlogs />
    </PageContainer>
  </Layout>
)

export default BlogIndexPage
