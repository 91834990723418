import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import ArticlePreview from '../../components/ArticlePreview/ArticlePreview'
import PropTypes from 'prop-types'

export const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      {posts &&
        posts.map(({ node: post }, i) => {
          const {
            timeToRead,
            frontmatter: { title, date, featureImage, tags },
            fields: { slug },
            excerpt,
          } = post

          return (
            <Fragment key={slug}>
              <ArticlePreview
                slug={slug}
                featureImage={featureImage}
                title={title}
                date={date}
                tags={tags}
                timeToRead={timeToRead}
              >
                {excerpt}
              </ArticlePreview>
              {i + 1 !== posts.length && <hr style={{ margin: '2em 0' }} />}
            </Fragment>
          )
        })}
    </>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
}

BlogRoll.defaultProps = {
  data: {},
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300, format: HTML)
              id
              fields {
                slug
              }
              timeToRead
              frontmatter {
                title
                templateKey
                date(formatString: "DD MMMM YYYY")
                tags
                featureImage {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
